import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class MainService {
  public user: any;
  public empresa: any;
  public headers: Headers;
  public baseUrl: String = "https://develop-medicinauniandes-bitacoras.imagineapps.co/";
  //public baseUrl: String = "http://localhost:8080/";

  constructor(public http: Http, private authService: AuthService) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Authorization", this.authService.getToken());
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
  }

  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
  get({ api }: { api: String }) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    const token = localStorage.getItem("id_token");
    this.headers.append("x-token", token);
    this.user = JSON.parse(localStorage.getItem("usuario")) || {};
    return this.http
      .get(`${this.baseUrl}${api}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  post({ api, data }: { api: String; data: any }) {
    return this.http
      .post(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }

  /**
   * API Generico DELETE
   * @param {string} api route: api/componente/id
   * @return {Observable<any>} respuesta asincrónica
   */
  delete({ api }: { api: String }) {
    return this.http
      .delete(`${this.baseUrl}${api}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  put({ api, data }: { api: String; data: any }) {
    return this.http
      .put(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map(res => res.json()));
  }
}
